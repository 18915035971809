<template>
    <div>
        <v-progress-linear :active="isLoading" indeterminate color="primary"></v-progress-linear>
        <GmapMap :center="centerLocation" :zoom="15" map-type-id="terrain" style="width: 100%; height: 500px">
            <gmap-info-window
                :options="infoOptions"
                :position="infoWinPosition"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen = false">
                <h1 class="font-weight-black text-uppercase subtitle-1">{{ selectedPark.name }}</h1>
                <h1 class="subtitle-2">{{ selectedPark.address }}</h1>
                <div class="mt-5">
                    <v-chip class="ma-2" :color="chipColor">
                        {{ selectedPark.freeLots }} / {{ selectedPark.totalCapacity }} {{ $t('Spots') }}</v-chip
                    >
                    <v-btn
                        @click="$router.push({ name: 'ParkProfile', params: { id: selectedPark.id } })"
                        outlined
                        color="primary"
                        class="is-lowecase">
                        <v-icon left> {{ icons.mdiPlus }} </v-icon>
                        Ver mais
                    </v-btn>
                </div>
            </gmap-info-window>
            <GmapMarker
                :key="index"
                v-for="(park, index) in parkList"
                :position="{ lat: park.latitude, lng: park.longitude }"
                :icon="getMarkers(park)"
                :clickable="true"
                :draggable="false"
                :zIndex="getZindex(park)"
                @click="onClick(park)" />
        </GmapMap>
    </div>
</template>

<script>
    import { mdiPlus } from '@mdi/js';
    // sidebar
    import useParkList from './useParkList';

    export default {
        props: {
            parkList: {
                type: Array,
                required: true,
            },
        },
        setup() {
            const {
                parkListTable,

                fetchParkList,
            } = useParkList();

            return {
                parkListTable,

                fetchParkList,
                icons: {
                    mdiPlus,
                },
            };
        },
        created() {
            this.isLoading = true;

            this.fetchParkList();
            setTimeout(() => {
                this.isLoading = false;
            }, 1900);
        },

        data() {
            return {
                selectedPark: {
                    name: '',
                    id: null,
                    address: '',
                    freeLots: null,
                    totalCapacity: null,
                },
                isLoading: false,
                options: {
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: false,
                    streetViewControl: true,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUI: false,
                    mapMarker: '',
                    gestureHandling: 'greedy',
                },
                myLocation: {
                    lat: 0,
                    lng: 0,
                },
                markers: [],
                redMarker: {
                    url: require('@/assets/icons/map/redPark.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                redMarkerActive: require('@/assets/icons/map/redPark.svg'),
                orangeMarker: {
                    url: require('@/assets/icons/map/orangePark.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                orangeMarkerActive: {
                    url: require('@/assets/icons/map/orangePark.svg'),
                },
                yellowMarker: {
                    url: require('@/assets/icons/map/yellowPark.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                yellowMarkerActive: {
                    url: require('@/assets/icons/map/yellowPark.svg'),
                },
                greenMarker: {
                    url: require('@/assets/icons/map/greenPark.svg'),
                    scaledSize: {
                        width: 55,
                        height: 55,
                    },
                },
                greenMarkerActive: require('@/assets/icons/map/greenPark.svg'),
                infoWinOpen: false,
                isClicked: false,
                selectedMarker: null,
                infoOptions: {
                    //optional: offset infowindow so it visually sits nicely on top of our marker
                    pixelOffset: {
                        width: 0,
                        height: -80,
                    },
                },
                infoWinPosition: {
                    lat: 0,
                    lng: 0,
                },
            };
        },
        computed: {
            centerLocation() {
                return {
                    //Estádio
                    lat: 41.4014,
                    lng: -8.5224,
                };
            },
            parkMarkers() {
                return this.parkListTable;
            },
            chipColor() {
                if (this.selectedPark.freeLots / this.selectedPark.totalCapacity === 1) {
                    return 'red';
                } else if (this.selectedPark.freeLots / this.selectedPark.totalCapacity >= 0.8) {
                    return 'orange';
                } else if (this.selectedPark.freeLots / this.selectedPark.totalCapacity >= 0.5) {
                    return 'amber';
                } else {
                    return 'green';
                }
            },
        },
        methods: {
            getMarkers(park) {
                if (this.selectedMarker == park.id) {
                    if (park.freeLots / park.totalCapacity === 1) {
                        return this.redMarkerActive;
                    } else if (park.freeLots / park.totalCapacity >= 0.8) {
                        return this.orangeMarkerActive;
                    } else if (park.freeLots / park.totalCapacity >= 0.5) {
                        return this.yellowMarkerActive;
                    } else {
                        return this.greenMarkerActive;
                    }
                } else {
                    if (park.freeLots / park.totalCapacity === 1) {
                        return this.redMarker;
                    } else if (park.freeLots / park.totalCapacity >= 0.8) {
                        return this.orangeMarker;
                    } else if (park.freeLots / park.totalCapacity >= 0.5) {
                        return this.yellowMarker;
                    } else {
                        return this.greenMarker;
                    }
                }
            },
            getZindex(park) {
                if (this.selectedMarker == park.id) {
                    return 1;
                } else {
                    return null;
                }
            },
            onClick(park) {
                this.selectedMarker = park.id;
                this.selectedPark = {
                    name: park.name,
                    id: park.id,
                    address: park.address,
                    freeLots: park.freeLots,
                    totalCapacity: park.totalCapacity,
                };

                this.infoWinOpen = true;
                this.infoWinPosition = {
                    lat: park.latitude,
                    lng: park.longitude,
                };
            },
        },
    };
</script>
